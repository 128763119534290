/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCUgQiAtGonugHxqppjoQmumc8g5fNcBfM",
  "appId": "1:914334626258:web:76d6db82463ace070d980b",
  "authDomain": "schooldog-i-newton-ga.firebaseapp.com",
  "measurementId": "G-XK9H9RQLW1",
  "messagingSenderId": "914334626258",
  "project": "schooldog-i-newton-ga",
  "projectId": "schooldog-i-newton-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-newton-ga.appspot.com"
}
